import { validations } from 'lwp-shared';

export const TEACHER = 'TEACHER';
export const SCHOOL = 'SCHOOL';
export const PARENT = 'PARENT';
export const HELPER = 'HELPER';
export const TOPICACCESS = 'TOPICACCESS';
export const MAGICPARENT = 'MAGICPARENT';
export const ADMIN = 'ADMIN';
export const GROUP = 'GROUP';
export const READING = 'READING';
export const PUPIL = 'PUPIL';
export const BOTH = 'BOTH';
export const CLASS = 'CLASS';
export const TOPIC = 'TOPIC';
export const SCHOOLLEADER = 'SCHOOLLEADER';

export const PAGINATION_ITEM_LIMIT = 5;

export const MINIO_PUBLIC_PATHS = {
    PUPILGENERATEDMATHSCOTS: 'generated/img/mathscots',
    PUPILMATHSCOTS: 'static/img/mathscots',
    SITEIMAGE: 'static/img/site',
    PUPILMATHSCOTCOLORS: 'static/img/mathscots/colors',
    PUPILMATHSCOTICONS: 'static/img/mathscots/icons'
};

export const DEFAULT_PARENT_WITH_CHILD_MATHSCOT = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/parent-child-high-five.png`;
export const DEFAULT_MWP_TEACHER_MATHSCOT = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/teacher-img.png`;
export const DEFAULT_LWP_TEACHER_MATHSCOT = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/lwp_teacher.png`;
export const DEFAULT_CHILD_IMAGE = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/child-mathscot.png`;
export const TV_IMAGE = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/tv.png`;
export const DEFAULT_EMPTY_IMAGE = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/question-mark-new.png`;
export const DEFAULT_READING_EMPTY_IMAGE = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/question.svg`;
// export const HOME_ICON = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/homeIcon.svg`;
export const DEFAULT_CHILD_IMAGE_WITH_RED_SHIRT = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/child-mathscot-red-shirt.png`;
export const DEFAULT_READING_IMAGE = `${process.env.NEXT_PUBLIC_MINIO_PUBLIC_BASE_URL}/${MINIO_PUBLIC_PATHS.SITEIMAGE}/Book_Open_Purple.png`;

export const onlyNumberRegex = /^[0-9]+$/;
export const phoneRegex = validations.user.phoneRegex;
export const emailRegex = validations.user.emailRegex;

export const isNumber = /\+|[0-9]{2}/;

export const fileNameRE = /^[a-zA-Z0-9_-]*$/;

export const adminPath = '/admin';
export const readingAsASubjectId = '0';
export const cypressLoginCode = 'CYPRESS_SOCIAL_LOGIN_CODE';

export const characters = ['Dog', 'Meerkat', 'Owl', 'Cat', 'Parrot', 'Rabbit', 'Monkey', 'Koala', 'Penguin', 'Panda'];
export const colours = ['Natural', 'Purple', 'Green'];

export const teacherRole = [
    {
        key: 'Class teacher',
        value: 'CLASS_TEACHER'
    },
    {
        key: 'School leader',
        value: 'SCHOOL_LEADER'
    }
];

export const unexpectedError = {
    message: 'Unexpected error. Please refresh the page and try again.',
    networkError: { statusCode: 535 }
};

export const STATUS_CODES = {
    _403: 403,
    _538: 538,
    _537: 537,
    _536: 536,
    _535: 535,
    _539: 539,
    _540: 540,
    INVALID_MAGIC_LINK: 540,
    ACCOUNT_NOT_ACTIVE: 541,
    FAILED_EMAIL: 542,
    EXPIRED_MAGIC_LINK: 543,
    SOCIAL_ACCOUNT_NOT_FOUND: 544,
    SOCIAL_ACCOUNT_NOT_LINKED: 545,
    CANNOT_LINK_SOCIAL_ACCOUNT: 546,
    WRONG_SOCIAL_ACCOUNT: 547
};

export const SchoolClassStatus = {
    ACTIVE: 'ACTIVE',
    IMPORTED: 'IMPORTED',
    HIDDEN: 'HIDDEN'
};

export const TagsCategoryType = {
    Topics: 'TOPIC_TAG',
    Schools: 'SCHOOL_TAG',
    Activities: 'ACTIVITY_TAG',
    Pupil: 'PUPIL_TAG'
};

export const PupilType = {
    BOTH: 'BOTH',
    TOPIC: 'TOPIC',
    READING: 'READING'
};

export const UserAssociatedLoginType = {
    MICROSOFT: 'MICROSOFT',
    GOOGLE: 'GOOGLE',
    PASSWORD: 'PASSWORD',
    FACEBOOK: 'FACEBOOK'
};

export const SocialLoginType = {
    microsoft: 'microsoft',
    google: 'google'
};

export const MAGIC_LINK_TYPES = {
    RESETPASSWORD: 'RESETPASSWORD',
    INVITATION: 'INVITATION',
    REJECT_INVITATION: 'REJECT_INVITATION',
    TOPICACCESS: 'TOPICACCESS',
    TROPHY: 'TROPHY',
    SCHOOL_IMPACT_REPORT: 'SCHOOL_IMPACT_REPORT',
    READING: 'READING'
};

export const notificationPreferenceEnum = {
    EMAILREMINDER: 'EMAILREMINDER',
    SMSREMINDER: 'SMSREMINDER',
    BOTH: 'BOTH',
    NOREMINDER: 'NOREMINDER'
};

export const photoPermissionsContent = {
    firstPermission:
        "I understand these comments and photos will be shared with my child's school or educational organisation, where it may be shared internally in accordance with their policies. Learning with Parents will store these in line with the terms of their",
    secondPermission:
        "I give permission for Learning with Parents and my child's school or educational organisation to use my comments and photos in marketing materials and on other social media. In all cases we will never disclose your name or contact details when we use the comments/photos."
};

export const PupilMathscotsCharacter = {
    MONKEY: 'Monkey',
    PANDA: 'Panda',
    KOALA: 'Koala',
    PENGUIN: 'Penguin',
    DOG: 'Dog',
    MEERKAT: 'Meerkat',
    OWL: 'Owl',
    CAT: 'Cat',
    PARROT: 'Parrot',
    RABBIT: 'Rabbit'
};

export const PupilMathscotsColour = {
    NATURAL: 'Natural',
    PURPLE: 'Purple',
    GREEN: 'Green'
};

export const UserStatus = {
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    IMPORTED: 'IMPORTED',
    ACCOUNTLESS: 'ACCOUNTLESS',
    DEACTIVATED: 'DEACTIVATED',
    REJECTED: 'REJECTED'
};

export const PupilParentStatus = {
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    IMPORTED: 'IMPORTED',
    REJECTED: 'REJECTED'
};

export const PupilStatus = {
    ACTIVE: 'ACTIVE',
    MISSING: 'MISSING',
    REGISTERED: 'REGISTERED'
};

export const TestEmailType = {
    adminInvitationEmail: 'adminInvitationEmail',
    parentInvitationEmail: 'parentInvitationEmail',
    schoolLeaderInvitationEmail: 'schoolLeaderInvitationEmail',
    sendMailForAllPupilSignUp: 'sendMailForAllPupilSignUp',
    sendMailForOneThirdPupilSignUp: 'sendMailForOneThirdPupilSignUp',
    sendMailForTwoThirdPupilSignUp: 'sendMailForTwoThirdPupilSignUp',
    sendMailForTopicReminderAfter7Days: 'sendMailForTopicReminderAfter7Days',
    sendParentWelcomeMail: 'sendParentWelcomeMail',
    sendPupilTopics: 'sendPupilTopics',
    sendQuestionToLwpStaff: 'sendQuestionToLwpStaff',
    sendQuestionToUser: 'sendQuestionToUser',
    sendResetPasswordEmail: 'sendResetPasswordEmail',
    sendTeacherAssignedToClass: 'sendTeacherAssignedToClass',
    sendTeacherTopicsToBeReleasedTomorrow: 'sendTeacherTopicsToBeReleasedTomorrow',
    sendTeacherWelcomeMail: 'sendTeacherWelcomeMail',
    teacherInvitationEmail: 'teacherInvitationEmail',
    sendTrophyMailToParent: 'sendTrophyMailToParent',
    sendTrophyMailToTeacher: 'sendTrophyMailToTeacher  '
};

export const CronJobOptions = {
    sendReleasedPupilTopicNotifications: 'sendReleasedPupilTopicNotifications',
    teacherTopicsToBeReleasedTomorrow: 'teacherTopicsToBeReleasedTomorrow',
    topicReminderAfter7Days: 'topicReminderAfter7Days',
    updateNoLinkedParentStatusOfPupils: 'updateNoLinkedParentStatusOfPupils',
    sendTrophyMailToPupil: 'sendTrophyMailToPupil',
    sendTrophyMailToTeacher: 'sendTrophyMailToTeacher',
    sendTrophyMailToSchoolLeader: 'sendTrophyMailToSchoolLeader',
    logsCleanUp: 'logsCleanUp',
    createReadingTopics: 'createReadingTopics',
    trophiesAssignedToPupils: 'trophiesAssignedToPupils',
    refreshAllAdminQueries: 'refreshAllAdminQueries',
    readingTopicReminders: 'readingTopicReminders',
    sendTeacherReadingTopicEnd: 'sendTeacherReadingTopicEnd',
    reimportSchoolsWonde: 'reimportSchoolsWonde',
    runHourlySqlQueries: 'runHourlySqlQueries',
    runNightlySqlQueries: 'runNightlySqlQueries',
    updateAllInterstitialFormsContextMutation: 'updateAllInterstitialFormsContextMutation',
    sendAllPendingNotifications: 'sendAllPendingNotifications',
    sendParentNotificationOfTeacherReadingComment: 'sendParentNotificationOfTeacherReadingComment',
    sendParentNotificationOfTeacherFeedbackReply: 'sendParentNotificationOfTeacherFeedbackReply'
};

export const TestEmailTypeOptions = [
    { key: 'adminInvitationEmail', value: 'Admin Invitation Email' },
    { key: 'parentInvitationEmail', value: 'Parent Invitation Email' },
    { key: 'userRequestedSignUpLink', value: 'User Requested Signup Link Email' },
    { key: 'schoolLeaderInvitationEmail', value: 'School Leader Invitation Email' },
    { key: 'sendMailForAllPupilSignUp', value: 'Email For All Pupil SignUp' },
    { key: 'sendMailForOneThirdPupilSignUp', value: 'Email For One Third Pupil SignUp' },
    { key: 'sendMailForTwoThirdPupilSignUp', value: 'Email For Two Third SignUp' },
    {
        key: 'sendMailForTopicReminderAfter7Days',
        value: 'Send Mail For Topic Reminder After 7 Days'
    },
    { key: 'sendParentWelcomeMail', value: 'Send Parent Welcome Mail' },
    { key: 'sendPupilTopics', value: 'Send Pupil Topics' },
    { key: 'sendQuestionToLwpStaff', value: 'Send Question To LWP Staff' },
    { key: 'sendQuestionToUser', value: 'Send Question To User' },
    { key: 'sendResetPasswordEmail', value: 'Send Reset Password Email' },
    { key: 'sendTeacherAssignedToClass', value: 'Send Teacher Assigned To Class' },
    {
        value: 'Send Teacher Topics To Be Released Tomorrow',
        key: 'sendTeacherTopicsToBeReleasedTomorrow'
    },
    {
        value: 'Send Teacher Reading Topic Ended Notification',
        key: 'sendTeacherReadingTopicEnd'
    },
    {
        value: 'Send Parent Reading Topic Release',
        key: 'sendPupilReadingTopicReleased'
    },
    {
        value: 'Send Mail For Reading Topic Reminder After 2 Days',
        key: 'readingTopicReminderAfter2Days'
    },
    {
        value: 'Send Mail For Reading Topic Reminder After 5 Days',
        key: 'readingTopicReminderAfter5Days'
    },
    { key: 'sendTeacherWelcomeMail', value: 'Send Teacher Welcome Mail' },
    { key: 'teacherInvitationEmail', value: 'Teacher Invitation Email' },
    { key: 'sendTrophyMailToParent', value: 'Send Trophy Mail To Parent' },
    { key: 'sendTrophyMailToTeacher', value: 'Send Trophy Mail To Teacher' },
    { key: 'updatedMagicLink', value: 'Updated Magic Link' },
    { key: 'deprecatedTemplate', value: 'Deprecated Template' },
    { key: 'sendParentNotificationOfTeacherReadingComment', value: 'Send parent notification of teacher reading log comment' },
    { key: 'sendParentNotificationOfTeacherFeedbackReply', value: 'Send parent notification of teacher feedback reply' },
    { key: 'sendTeacherReadingTopicHolidayOverlap', value: 'Send teacher reading topic holiday overlap' },
    { key: 'invoiceOfSchoolClassLicenceToAdmin', value: 'Send school class licence invoice to admin' },
    { key: 'invoiceOfSchoolClassLicenceToSchool', value: 'Send school class licence invoice to school' }
];

export const sendRegistrationMail = ['BASEURL', 'USERSTATUS', 'FIRSTNAME', 'LASTNAME', 'TEACHERALIAS', 'CLASS', 'SCHOOL', 'REGISTEREDLIST'];

export const notificationEmailParameters = {
    sendTrophyMailToParent: {
        always: ['MAGICID', 'PUPILID', 'TROPHYNAME', 'CHILDFIRSTNAME', 'CHILDLASTNAME'],
        conditional: []
    },
    sendTrophyMailToTeacher: {
        always: ['BASEURL', 'CLASSID', 'TROPHYNAME', 'MESSAGE'],
        conditional: ['CLASS']
    },
    sendPupilTopics: {
        always: [
            'MAGICID',
            'PUPILID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE',
            'PARENTSTATUS',
            'PUPILSTATUS',
            'PUPILPARENTSTATUS',
            'PENDINGTOPICS'
        ],
        conditional: [
            'SCHOOL',
            'TOPICNAME',
            'TEACHERALIAS',
            'DUEDATE',
            'PUPILPARENTINVITATIONAT',
            'PARENTINVITATIONAT',
            'REJECT_MAGICID',
            'NEW_STICKERS'
        ]
    },

    sendPupilReadingTopicReleased: {
        always: [
            'MAGICID',
            'PUPILID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE',
            'TARGET',
            'PARENTSTATUS',
            'PUPILSTATUS',
            'PUPILPARENTSTATUS'
        ],
        conditional: [
            'SCHOOL',
            'TOPICNAME',
            'TEACHERALIAS',
            'DUEDATE',
            'PUPILPARENTINVITATIONAT',
            'PARENTINVITATIONAT',
            'REJECT_MAGICID',
            'NEW_STICKERS'
        ]
    },

    updatedMagicLink: {
        always: [
            'BASEURL',
            'MAGICID',
            'PUPILID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE',
            'PARENTSTATUS',
            'PUPILSTATUS',
            'PUPILPARENTSTATUS',
            'TARGET_URL'
        ],
        conditional: [
            'SCHOOL',
            'TOPICNAME',
            'TEACHERALIAS',
            'DUEDATE',
            'PUPILPARENTINVITATIONAT',
            'PARENTINVITATIONAT',
            'REJECT_MAGICID',
            'TARGET'
        ]
    },
    sendTeacherTopicsToBeReleasedTomorrow: {
        always: [
            'SCHOOL',
            'TOPICNAME',
            'CLASS',
            'TEACHERALIAS',
            'RELEASEDATE',
            'ISRELEASED',
            'TEACHERPDF_FILENAME',
            'PARENTTOPICNAME',
            'CLASSID'
        ],
        conditional: ['DUEDATE', 'TOPICMESSAGE']
    },
    sendTeacherReadingTopicEnd: {
        always: ['TOPICNAME', 'PARENTTOPICNAME', 'TEACHERALIAS', 'CLASS', 'RELEASEDATE', 'ISRELEASED', 'TARGET', 'CLASSID', 'DUEDATE'],

        conditional: []
    },
    sendRegistrationMail: {
        always: ['BASEURL', 'USERSTATUS', 'FIRSTNAME', 'LASTNAME', 'TEACHERALIAS', 'CLASS', 'SCHOOL', 'REGISTEREDLIST'],
        conditional: []
    },
    sendMailForTopicReminderAfter7Days: {
        always: [
            'PUPILID',
            'MAGICID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE'
        ],
        conditional: ['SCHOOL', 'TOPICNAME', 'TEACHERALIAS', 'DUEDATE']
    },
    readingTopicReminderAfter5Days: {
        always: [
            'PUPILID',
            'MAGICID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE'
        ],
        conditional: ['SCHOOL', 'TOPICNAME', 'TEACHERALIAS', 'DUEDATE']
    },
    readingTopicReminderAfter7Days: {
        always: [
            'BASEURL',
            'USERSTATUS',
            'FIRSTNAME',
            'LASTNAME',
            'PUPILID',
            'PUPILTOPICID',
            'CLASSID',
            'CLASSTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'SCHOOL',
            'TOPICNAME',
            'TOPICSUBJECT',
            'TOPICMESSAGE',
            'CLASS',
            'TEACHERALIAS',
            'TEACHERMESSAGE',
            'RELEASEDATE',
            'DUEDATE',
            'READABLEDATE',
            'PENDINGTOPICS'
        ],
        conditional: []
    },
    readingTopicReminderAfter2Days: {
        always: [
            'PUPILID',
            'MAGICID',
            'PUPILTOPICID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'CLASSID',
            'CLASSTOPICID',
            'RELEASEDATE'
        ],
        conditional: ['SCHOOL', 'TOPICNAME', 'TEACHERALIAS', 'DUEDATE']
    },
    sendQuestionToUser: {
        always: ['BASEURL', 'USERQUESTION'],
        conditional: []
    },
    sendQuestionToLwpStaff: {
        always: ['BASEURL', 'USERQUESTION', 'USERFIRSTNAME', 'USERLASTNAME', 'USERTYPE'],
        conditional: ['SCHOOL']
    },
    schoolLeaderInvitationEmail: {
        always: ['BASEURL', 'INVITEDBY', 'SCHOOL', 'MAGICID', 'REJECT_MAGICID', 'TOTALLICENSES', 'FULLPROGRAMMELICENSES'],
        conditional: []
    },
    adminInvitationEmail: {
        always: ['BASEURL', 'MAGICID', 'REJECT_MAGICID'],
        conditional: ['INVITEDBY']
    },
    teacherInvitationEmail: {
        always: ['BASEURL', 'INVITEDBY', 'CODE', 'SCHOOL', 'MAGICID', 'REJECT_MAGICID', 'READINGLOGCLASSES', 'FULLPROGRAMMECLASSES'],
        conditional: []
    },
    parentInvitationEmail: {
        always: ['BASEURL', 'CODE', 'MAGICID', 'REJECT_MAGICID'],
        conditional: []
    },
    userRequestedSignUpLink: {
        always: ['CODE', 'BASEURL', 'MAGICID', 'FIRSTNAME', 'USERSTATUS', 'REJECT_MAGICID', 'RESETPASSTOKEN'],
        conditional: []
    },
    sendResetPasswordEmail: {
        always: ['BASEURL', 'MAGICID', 'USERSTATUS'],
        conditional: []
    },
    sendTeacherAssignedToClass: {
        always: ['BASEURL', 'CLASS', 'SCHOOL', 'TEACHERALIAS', 'CLASSID', 'ISFULLPROGRAMMECLASS'],
        conditional: []
    },
    sendParentNotificationOfTeacherReadingComment: {
        always: [
            'PUPILID',
            'CLASSTOPICID',
            'PUPILTOPICID',
            'CLASSID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'CLASS',
            'RELEASEDATE',
            'READINGENTRYDATE',
            'COMMENT',
            'MAGICID'
        ],
        conditional: ['SCHOOL', 'TEACHERALIAS']
    },
    sendParentNotificationOfTeacherFeedbackReply: {
        always: [
            'FIRSTNAME',
            'LASTNAME',
            'BASEURL',
            'USERSTATUS',
            'REPLY',
            'PUPILID',
            'PUPILFEEDBACKID',
            'CHILDFIRSTNAME',
            'CHILDLASTNAME',
            'FIRSTNAME',
            'LASTNAME',
            'TEACHERALIAS',
            'ISREADING',
            'TOPICNAME',
            'ACTIVITYNAME'
        ],
        conditional: []
    },
    sendTeacherReadingTopicHolidayOverlap: {
        always: [
            'SCHOOL',
            'CLASS',
            'TEACHERALIAS',
            'CLASSTOPICID',
            'RELEASEDATE',
            'CLASSID',
            'HOLIDAYNAME',
            'HOLIDAYSTARTDATE',
            'HOLIDAYENDDATE',
            'BASEURL',
            'TEACHERALIAS'
        ],
        conditional: []
    },
    invoiceOfSchoolClassLicenceToAdmin: {
        always: [
            'EMAIL',
            'ADDITIONALFULLPROGRAMMECLASSCOUNT',
            'ADDITIONALREADINGONLYCLASSCOUNT',
            'ADDITIONALUPGRADECLASSCOUNT',
            'ADDITIONALFULLPROGRAMMECLASSPRICE',
            'ADDITIONALREADINGONLYCLASSPRICE',
            'ADDITIONALUPGRADECLASSPRICE',
            'TOTALPRICE',
            'SCHOOLID',
            'SCHOOLNAME',
            'USEREMAIL',
            'USERID',
            'USERFIRSTNAME',
            'USERLASTNAME',
            'USERALIAS',
            'ADDITIONALEMAILS'
        ],
        conditional: []
    },
    invoiceOfSchoolClassLicenceToSchool: {
        always: [
            'FIRSTNAME',
            'LASTNAME',
            'EMAIL',
            'ADDITIONALFULLPROGRAMMECLASSCOUNT',
            'ADDITIONALREADINGONLYCLASSCOUNT',
            'ADDITIONALUPGRADECLASSCOUNT',
            'ADDITIONALFULLPROGRAMMECLASSPRICE',
            'ADDITIONALREADINGONLYCLASSPRICE',
            'ADDITIONALUPGRADECLASSPRICE',
            'TOTALPRICE',
            'SCHOOLID',
            'SCHOOLNAME',
            'USEREMAIL',
            'USERID',
            'USERFIRSTNAME',
            'USERLASTNAME',
            'USERALIAS'
        ],
        conditional: []
    }
};

export const EmailTemplateStatus = {
    CURRENT: 'CURRENT',
    DRAFT: 'DRAFT',
    HISTORIC: 'HISTORIC'
};

export const classReadingProgress = {
    NOT_SET: 'NOT_SET',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED'
};

export const renderReadingTypeImg = {
    SCHOOL_BOOK: 'new-school-book',
    OWN_BOOK: 'new-own-book',
    SOMETHING_ELSE: 'new-something-else'
};

export const readingType = {
    SCHOOL_BOOK: 'SCHOOL_BOOK',
    OWN_BOOK: 'OWN_BOOK',
    SOMETHING_ELSE: 'SOMETHING_ELSE'
};

export const TooltipType = {
    CHILD_SWITCH: 'CHILD_SWITCH',
    SCHOOL_READING_LOG: 'SCHOOL_READING_LOG',
    TOPIC_LIBRARY: 'TOPIC_LIBRARY',
    TICK_ALL_FEEDBACK_AS_READ: 'TICK_ALL_FEEDBACK_AS_READ',
    TICK_SINGLE_FEEDBACK_AS_READ: 'TICK_SINGLE_FEEDBACK_AS_READ',
    REPLY_ON_FEEDBACK: 'REPLY_ON_FEEDBACK',
    AWARD_STICKERS_TO_FEEDBACK: 'AWARD_STICKERS_TO_FEEDBACK'
};

export const FeedbackType = {
    READING: 'READING',
    ACTIVITY: 'ACTIVITY'
};

export const StickerCharacterItemType = {
    CHARACTER: 'CHARACTER',
    ITEM: 'ITEM'
};

export const themColors = ['#F28D00', '#9BC53D', '#39B0E2', '#887DFF', '#922C52', '#00303C', '#6F60AE', '#C66C00'];

export const IMPACT_REPORT_GRAPH_TITLES = {
    TOPIC_ACTIVITY: {
        engagementByYearGroup: {
            title: 'One topic completion (by year group)',
            description: 'This graph shows the percentage of families who have completed 1 topic per year group.'
        },
        engagementByClass: {
            title: 'One topic completion (by class)',
            description: 'This graph shows the percentage of families who have completed 1 topic per class.'
        },
        avgTopicSetByYearGroup: {
            title: 'Topic setting (by year group)',
            description:
                'We know that engagement has the best chance of improving when topics are set regularly and consistently. This graph shows the number of topics set by each year group since your schools launch.'
        },
        avgTopicSetByClass: {
            title: 'Topic setting (by class)',
            description:
                'We know that engagement has the best chance of improving when topics are set regularly and consistently. This graph shows the number of topics set by each class since your schools launch.'
        },
        buildingEngagement: {
            title: 'Building Engagement',
            description:
                'This graph shows the percentage of pupils who have completed more than one topic across your school. This is a good indication of your building engagement with the programme.'
        }
    },
    READING: {
        engagementByYearGroup: {
            title: 'Weekly target met (by year group)',
            description: 'This graph shows the percentage of pupils who have met the weekly reading target once by year group.'
        },
        engagementByClass: {
            title: 'Weekly target met (by class)',
            description: 'This graph shows the percentage of pupils who have met the weekly reading target once by class.'
        },
        avgWeeklyTargetMetByYearGroup: {
            title: 'Average completion rate (by year group)',
            description:
                'This graph shows the percentage of weekly reading targets that have been met, out of all the individual weekly targets set for each pupil.'
        },
        avgWeeklyTargetMetByClass: {
            title: 'Average completion rate (by class)',
            description:
                'This graph shows the percentage of weekly reading targets that have been met, out of all the individual weekly targets set for each pupil.'
        },
        buildingEngagement: {
            title: 'Building Engagement',
            description:
                'This graph shows the percentage of pupils who have achieved your weekly reading target across your school. This is a good indication of your building engagement with the programme.'
        }
    }
};

export const IMPACT_REPORT_PROGRAM_OVERVIEW_DESCRIPTION = {
    TOPIC_ACTIVITY: {
        activeFamilyDescription:
            'This is the percentage of families who have watched at least one video or created at least one entry on the site this academic year',
        activePremiumFamilyDescription:
            'This is the percentage of pupil premium families who have watched at least one video or created at least one entry on the site this academic year',
        numberOfWeeksText: 'Number of weeks programme has been running:'
    },
    READING: {
        activeFamilyDescription: 'This is the percentage of pupils who have left a comment or feedback on the site this academic year',
        activePremiumFamilyDescription:
            'This is the percentage of premium pupils who have left a comment or feedback on the site this academic year',
        numberOfWeeksText: 'Number of weeks the log has been live'
    }
};
